import React, { ReactNode, useState } from "react";
import { selectInvoice } from "../../invoiceStore";
import "./PaymentAssets.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { PaymentStepEnum } from "../../Invoice";
import { setSelectedPaymentAsset } from "../../SelectedPaymentAssetStore";
import BitcoinLogo from "../../../../assets/images/bitcoin.svg";
import UsdcLogo from "../../../../assets/images/usdc.svg";
import UsdtLogo from "../../../../assets/images/usdt.svg";
import EthereumLogo from "../../../../assets/images/eth.svg";

export enum AllowedAssetEnum {
  USDC = "USDC",
  USDT = "USDT",
  BTC = "BTC",
  ETH = "ETH",
}

function PaymentAssets(props: { onNextStep: (step: PaymentStepEnum) => void }) {
  const dispatch = useAppDispatch();
  const [selectedAsset, setSelectedAsset] = useState("");
  const selectedInvoice = useAppSelector(selectInvoice);

  const assetChange = (currentAsset: string): void => {
    setSelectedAsset(currentAsset);
  };

  const onNextStep = (): void => {
    if (selectedAsset === "BTC") {
      props.onNextStep(PaymentStepEnum.STATE_PAY);

      dispatch(
        setSelectedPaymentAsset({
          selectedMethod: "Bitcoin",
          asset: "BTC",
          methods: [],
        }),
      );
      return;
    }
    props.onNextStep(PaymentStepEnum.STATE_CHOOSE_METHOD);

    const selectedPA = selectedInvoice.allowedPaymentAssets.find((i) => i.asset === selectedAsset);

    dispatch(setSelectedPaymentAsset(selectedPA));
  };

  const renderImage = (currentAsset: string): ReactNode => {
    switch (currentAsset) {
      case AllowedAssetEnum.USDC:
        return <img src={UsdcLogo} alt={currentAsset} />;
      case AllowedAssetEnum.USDT:
        return <img src={UsdtLogo} alt={currentAsset} />;
      case AllowedAssetEnum.BTC:
        return <img src={BitcoinLogo} alt={currentAsset} />;
      case AllowedAssetEnum.ETH:
        return <img src={EthereumLogo} alt={currentAsset} />;
      default:
        return;
    }
  };

  const renderFullAssetName = (currentAsset: string): ReactNode => {
    switch (currentAsset) {
      case AllowedAssetEnum.USDC:
        return "USD Coin";
      case AllowedAssetEnum.USDT:
        return "Tether";
      case AllowedAssetEnum.BTC:
        return "Bitcoin";
      case AllowedAssetEnum.ETH:
        return "Ethereum";
      default:
        return;
    }
  };

  return (
    <div className='container'>
      <p className='text text__align--left'>
        Please select what cryptocurrency you would like to pay with:
      </p>
      {selectedInvoice.allowedPaymentAssets.map((item) => (
        <div
          key={item.asset}
          className={`container__asset ${
            selectedAsset === item.asset ? "container__asset--active" : ""
          }`}
          onClick={() => assetChange(item.asset)}
        >
          <div className='container__asset__logo'>{renderImage(item.asset)}</div>
          <div className='container__asset__name'>
            <div className='container__asset__name__full'>{renderFullAssetName(item.asset)}</div>
            <div className='container__asset__name__short'>{item.asset}</div>
          </div>
        </div>
      ))}
      <div className='container__buttons'>
        <button disabled={!selectedAsset} onClick={onNextStep}>
          {selectedAsset === "BTC" ? "Pay" : "Next"}
        </button>
      </div>
    </div>
  );
}

export default PaymentAssets;
