export interface InvoiceModel {
  id: string;
  amount: number;
  asset: string;
  title: string;
  description: string;
  expiresAt: string;
  paymentOrders: OrderPaymentModel[];
  payments: PaymentModel[];
  allowedPaymentAssets: AllowedPaymentAssetsModel[];
}

export interface AllowedPaymentAssetsModel {
  asset: string;
  methods: string[];
  selectedMethod?: string;
}

export interface OrderPaymentModel {
  address: string;
  amountToPay: number;
  asset: string;
  createdAt: string;
  expiresAt: string;
  id: string;
  transactionNetwork: string;
  qrCode: string;
}

export interface PaymentModel {
  id: string;
  txid: string;
  address: string;
  amountPaid: number;
  paymentAsset: string;
  transactionNetwork: string;
  amountPaidInInvoiceAsset: number;
  isConfirmed: boolean;
  isRejected: boolean;
  receivedAt: string;
}

export enum TransactionNetworkEnum {
  BITCOIN = "Bitcoin",
  ETHEREUM = "Ethereum",
  TRON = "Tron",
}
