import React, { useEffect, useState } from "react";
import "./PaymentPay.scss";
import Counter from "components/counter/Counter";
import Qrcode from "components/qrcode/Qrcode";
import { InvoiceModel, OrderPaymentModel } from "model/Invoice";
import { MdOutlineContentCopy } from "react-icons/md";
import { BiInfoCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import moment from "moment";
import PaymentStatus, { PaymentStatusEnum } from "../paymentStatus/PaymentStatus";
import { getInvoiceDetails, initializeInvoice } from "../../../../api/api";
import { from } from "rxjs";
import { selectedPaymentAsset } from "../../SelectedPaymentAssetStore";
import Loader from "../loader/Loader";
import { checkInvoiceStatus } from "helpers/invoices";
import ReactTooltip from "react-tooltip";
import BasicTable from "../../../../components/basic-table/basic-table";
import { Stack } from "@mui/material";
import { loadInvoiceSuccess } from "../../invoiceStore";

function PaymentPay(props: {
  selectedInvoice: InvoiceModel;
  shouldInitialize: boolean;
  currentStatusCode: PaymentStatusEnum | null;
}) {
  const [paymentOrder, setPaymentOrder] = useState<any | null>(null);
  const [showStatusCode, setShowStatusCode] = useState<boolean>(!!props.currentStatusCode);
  const [currentStatusCode, setCurrentStatusCode] = useState<PaymentStatusEnum | null>(
    props.currentStatusCode || null,
  );
  const dispatch = useAppDispatch();
  const [time, setTime] = useState<number>(300);
  const [fullTime, setFullTime] = useState<number>(300);

  const selectedAsset = useAppSelector(selectedPaymentAsset);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;

    if (props.shouldInitialize) {
      from(
        initializeInvoice(
          props.selectedInvoice.id,
          selectedAsset.asset,
          selectedAsset.selectedMethod,
        ),
      ).subscribe((result) => {
        initData(result.data as OrderPaymentModel);
        timer = setInterval(() => {
          from(getInvoiceDetails(props.selectedInvoice.id)).subscribe((res) => {
            dispatch(loadInvoiceSuccess(res.data as InvoiceModel));

            const areAllPaymentsFinished = props.selectedInvoice.payments.every(
              (payment) => payment.isConfirmed || payment.isRejected,
            );

            verifyStatus(res.data as InvoiceModel, timer, areAllPaymentsFinished);
          });
        }, 5000);
      });
    } else {
      if (currentStatusCode !== PaymentStatusEnum.STATUS_INVOICE_EXPIRED) {
        initData(props.selectedInvoice.paymentOrders.at(-1) as OrderPaymentModel);
      }

      const areAllPaymentsFinished = props.selectedInvoice.payments.every(
        (payment) => payment.isConfirmed || payment.isRejected,
      );

      if (
        !currentStatusCode ||
        currentStatusCode === PaymentStatusEnum.STATUS_PENDING ||
        !areAllPaymentsFinished
      ) {
        timer = setInterval(() => {
          from(getInvoiceDetails(props.selectedInvoice.id)).subscribe((res) => {
            dispatch(loadInvoiceSuccess(res.data as InvoiceModel));
            verifyStatus(res.data as InvoiceModel, timer, areAllPaymentsFinished);
          });
        }, 5000);
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const initData = (currentPaymentOrder: OrderPaymentModel): void => {
    setPaymentOrder(currentPaymentOrder);
    var currentTime = moment.utc();
    var paymentExpiresAt = moment(currentPaymentOrder.expiresAt).utc();
    var paymentCreatedAt = moment(currentPaymentOrder.createdAt).utc();
    setTime(paymentExpiresAt.diff(currentTime, "seconds"));
    setFullTime(paymentExpiresAt.diff(paymentCreatedAt, "seconds"));
  };

  const verifyStatus = (
    invoice: InvoiceModel,
    timer: ReturnType<typeof setInterval>,
    areAllPaymentsFinished: boolean,
  ): void => {
    const invoiceStatus = checkInvoiceStatus(invoice);

    if (invoiceStatus !== PaymentStatusEnum.STATUS_TO_PROCESS) {
      setShowStatusCode(true);
      setCurrentStatusCode(invoiceStatus);

      if (invoiceStatus !== PaymentStatusEnum.STATUS_PENDING && areAllPaymentsFinished) {
        clearInterval(timer);
      }
    }
  };

  return !showStatusCode ? (
    paymentOrder ? (
      <>
        <div className='payment-wrapper'>
          <Counter time={time} fullTime={fullTime} />
          <p className='text'>
            To confirm your order, please send the exact amount of {paymentOrder?.asset} to the
            given address below, or scan the QR code
          </p>
          <p className='text'>Scan QR code to pay</p>
          <Qrcode qrCode={paymentOrder?.qrCode} />
        </div>
        <div className='container container--marginTop'>
          <div className='tooltip'>
            <p className='text text__tooltip text__align--left'>Pay this amount exactly</p>
            <BiInfoCircle
              data-tip='You must pay the exact amount displayed or the payment will cause an error. If you pay too little or too much the payment will fail. Do not send funds directly from exchanges, as this might fail the payment due to delayed time in clearing from an exchange. Always send funds from a wallet and make sure you pay the exact displayed amount'
              className='tooltip__icon'
            />
          </div>
          <div className='container__asset container__asset__copy'>
            <span>
              {paymentOrder?.amountToPay} | {paymentOrder?.asset}
            </span>
            <div className='copy__icon'>
              <MdOutlineContentCopy
                className='copy__icon-icon'
                onClick={() => {
                  navigator.clipboard.writeText(paymentOrder?.amountToPay);
                  toast("Amount copied to the clipboard");
                }}
              />
            </div>
          </div>
          <div className='tooltip'>
            <p className='text text__tooltip text__align--left'>Pay to this address</p>
            <BiInfoCircle
              data-tip='Please pay to this address only and make sure to copy and paste it correctly into your wallet application. If you use a mobile wallet, please scan the QR code. Do not pay directly from an exchange as transactions might fail due to how exchanges sent funds'
              className='tooltip__icon'
            />
          </div>
          <div className='container__asset container__asset__copy'>
            <span>{paymentOrder?.address}</span>
            <div className='copy__icon'>
              <MdOutlineContentCopy
                className='copy__icon-icon'
                onClick={() => {
                  navigator.clipboard.writeText(paymentOrder?.address);
                  toast("Address copied to the clipboard");
                }}
              />
            </div>
          </div>
          <ReactTooltip effect='solid' place='right' className='tooltip__base' />
        </div>
        <a href={paymentOrder?.qrCode} className='btn-link'>
          <button>PAY IN WALLET</button>
        </a>
      </>
    ) : (
      <>
        <Loader></Loader>
      </>
    )
  ) : (
    <Stack spacing={4}>
      <PaymentStatus status={currentStatusCode} />
      {props.selectedInvoice.payments.length > 0 &&
        (currentStatusCode === PaymentStatusEnum.STATUS_SUCCESS ||
          currentStatusCode === PaymentStatusEnum.STATUS_PENDING) && (
          <BasicTable
            payments={props.selectedInvoice.payments}
            asset={props.selectedInvoice.asset}
          />
        )}
    </Stack>
  );
}

export default PaymentPay;
