import React, { ReactNode, useState } from "react";
import "./PaymentMethod.scss";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectedPaymentAsset, setSelectedPaymentMethod } from "../../SelectedPaymentAssetStore";
import { PaymentStepEnum } from "../../Invoice";
import EthereumLogo from "../../../../assets/images/eth.svg";
import TronLogo from "../../../../assets/images/tron.svg";

export enum AllowedMethodEnum {
  ETHEREUM = "Ethereum",
  TRON = "Tron",
}

function PaymentMethod(props: { onNextStep: (step: PaymentStepEnum) => void }) {
  const dispatch = useAppDispatch();
  const [selectedMethod, setSelectedMethod] = useState("");

  const selectedAsset = useAppSelector(selectedPaymentAsset);

  const methodChange = (method: string): void => {
    setSelectedMethod(method);
  };

  const onBackStep = (): void => {
    props.onNextStep(PaymentStepEnum.STATE_CHOOSE_ASSET);
  };

  const onNextStep = (): void => {
    props.onNextStep(PaymentStepEnum.STATE_PAY);
    dispatch(setSelectedPaymentMethod(selectedMethod));
  };

  const renderImage = (currentMethod: string): ReactNode => {
    switch (currentMethod) {
      case AllowedMethodEnum.ETHEREUM:
        return <img src={EthereumLogo} alt={currentMethod} />;
      case AllowedMethodEnum.TRON:
        return <img src={TronLogo} alt={currentMethod} />;
      default:
        return;
    }
  };

  const renderShortMethodName = (currentMethod: string): ReactNode => {
    switch (currentMethod) {
      case AllowedMethodEnum.TRON:
        return "TRC-20";
      default:
        return;
    }
  };

  return (
    <>
      <div className='container'>
        <p className='text text__align--left'>
          Please select what network you would like to pay on:
        </p>
        {selectedAsset.methods.map((method) => (
          <div
            key={method}
            className={`container__asset ${
              selectedMethod === method ? "container__asset--active" : ""
            }`}
            onClick={() => methodChange(method)}
          >
            <div className='container__asset__logo'>{renderImage(method)}</div>
            <div className='container__asset__name'>
              <div className='container__asset__name__full'>{method}</div>
              <div className='container__asset__name__short'>{renderShortMethodName(method)}</div>
            </div>
          </div>
        ))}
      </div>

      <div className='container__buttons'>
        <button onClick={onBackStep}>Back</button>

        <button disabled={!selectedMethod} onClick={onNextStep}>
          Pay
        </button>
      </div>
    </>
  );
}

export default PaymentMethod;
