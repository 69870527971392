import React, { useEffect, useState } from "react";
import "./Counter.scss";
import { CounterModel } from "model/Counter";
import { takeWhile, timer } from "rxjs";

const calcDisplayTime = (time: number): string => {
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  const minutesLabel = minutes?.toString().length > 1 ? minutes.toString() : `0${minutes}`;
  const secondsLabel = seconds?.toString().length > 1 ? seconds.toString() : `0${seconds}`;
  return `${minutesLabel}:${secondsLabel}`;
};

function Counter(props: CounterModel) {
  const [countDownDate] = useState(new Date().getTime() + props.time * 1000);
  const [timeLeft, setTimeLeft] = useState(countDownDate - new Date().getTime());
  const [timeLabel, setTimeLabel] = useState(calcDisplayTime(timeLeft));
  const [progressBarWidth, setProgressBarWidth] = useState(
    `${(timeLeft / 1000 / props.fullTime) * 100}%`,
  );

  useEffect(() => {
    const timerSubscription = timer(0, 1000)
      .pipe(takeWhile(() => timeLeft > 0))
      .subscribe(() => {
        setTimeLeft(countDownDate - new Date().getTime());
        setProgressBarWidth(`${(timeLeft / 1000 / props.fullTime) * 100}%`);
        setTimeLabel(calcDisplayTime(timeLeft));
      });
    return () => timerSubscription?.unsubscribe();
  });

  return (
    <div className='progressBarWrapper'>
      <div className='progressBar'>
        <div className='progressBar-inner' style={{ width: progressBarWidth }}></div>
      </div>
      <p className='text'>Time left to pay this invoice</p>
      <h2>{timeLabel}</h2>
    </div>
  );
}

export default Counter;
