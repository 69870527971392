import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InvoiceModel } from "model/Invoice";
import { RootState } from "../../app/store";

const initialInvoiceState = {
  id: null,
  amount: null,
  asset: null,
  title: null,
  description: null,
  expiresAt: null,
  paymentOrders: [],
  payments: [],
  allowedPaymentAssets: [],
} as any;

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialInvoiceState,
  reducers: {
    loadInvoiceSuccess(state, action: PayloadAction<InvoiceModel>) {
      state.id = action.payload.id;
      state.amount = action.payload.amount;
      state.asset = action.payload.asset;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.expiresAt = action.payload.expiresAt;
      state.paymentOrders = action.payload.paymentOrders;
      state.payments = action.payload.payments;
      state.allowedPaymentAssets = action.payload.allowedPaymentAssets;
    },
  },
});

export const selectInvoice = (state: RootState) => state.invoice as InvoiceModel;
export const { loadInvoiceSuccess } = invoiceSlice.actions;
export default invoiceSlice.reducer;
