import { AllowedPaymentAssetsModel } from "model/Invoice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

const initialSelectedPaymentAssetState: AllowedPaymentAssetsModel = {
  asset: "",
  methods: [],
  selectedMethod: "",
};

const selectedPaymentAssetSlice = createSlice({
  name: "selectedPaymentAsset",
  initialState: initialSelectedPaymentAssetState,
  reducers: {
    setSelectedPaymentAsset(state, action: PayloadAction<AllowedPaymentAssetsModel | undefined>) {
      state.asset = action?.payload?.asset ?? "";
      state.methods = action?.payload?.methods ?? [];
      state.selectedMethod = action?.payload?.selectedMethod || "";
    },
    setSelectedPaymentMethod(state, action: PayloadAction<string>) {
      state.selectedMethod = action?.payload;
    },
  },
});

export const selectedPaymentAsset = (state: RootState) =>
  state.selectedPaymentAsset as AllowedPaymentAssetsModel;
export const { setSelectedPaymentAsset } = selectedPaymentAssetSlice.actions;
export const { setSelectedPaymentMethod } = selectedPaymentAssetSlice.actions;
export default selectedPaymentAssetSlice.reducer;
