import React from "react";
import "./Loader.scss";

function Loader() {
  return (
    <div id='loader'>
      <div className='sk-three-bounce'>
        <div className='sk-child sk-bounce1'></div>
        <div className='sk-child sk-bounce2'></div>
        <div className='sk-child sk-bounce3'></div>
      </div>
    </div>
  );
}

export default Loader;
