import React, { useEffect, useRef } from "react";
import "./Qrcode.scss";

function Qrcode(props: { qrCode: string }) {
  const QRCode = require("qrcode");
  const qrCodeRef = useRef(null);

  useEffect(() => {
    QRCode.toCanvas(qrCodeRef.current, props.qrCode);
  }, []);

  return (
    <div>
      <canvas ref={qrCodeRef}></canvas>
    </div>
  );
}

export default Qrcode;
