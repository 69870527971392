import * as React from "react";
import { useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PaymentModel } from "../../model/Invoice";
import DoneIcon from "@mui/icons-material/Done";
import { mapTransactionNetworkToAddressUrl, mapTransactionNetworkToTxUrl } from "helpers/invoices";
import { CircularProgress, Collapse, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveIcon from "@mui/icons-material/Remove";
import "./basic-table.scss";

type BasicTableProps = {
  payments: PaymentModel[];
  asset: string;
};

function Row(props: { row: PaymentModel }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const currentStatus = useMemo(
    () =>
      row.isConfirmed ? (
        <DoneIcon color='success' />
      ) : row.isRejected ? (
        <RemoveIcon color='error' />
      ) : (
        <CircularProgress style={{ width: "18px", height: "18px" }} />
      ),
    [row.isConfirmed, row.isRejected],
  );

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.amountPaid}
        </TableCell>
        <TableCell>{row.paymentAsset}</TableCell>
        <TableCell>{row.transactionNetwork}</TableCell>
        <TableCell>{currentStatus}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Stack spacing={2} mt={2} mb={2}>
              <Typography variant='caption' display='block' gutterBottom>
                <b>ADDRESS: </b>
                <a
                  href={mapTransactionNetworkToAddressUrl(row.transactionNetwork, row.address)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {row.address}
                </a>
              </Typography>
              <Typography variant='caption' display='block' gutterBottom>
                <b>TXID: </b>
                <a
                  href={mapTransactionNetworkToTxUrl(row.transactionNetwork, row.txid)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {row.txid}
                </a>
              </Typography>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const BasicTable: React.FC<BasicTableProps> = ({ payments, asset }): JSX.Element => {
  const totalAmountPaid = payments.reduce((acc, curr) => {
    return acc + curr.amountPaidInInvoiceAsset;
  }, 0);

  return (
    <>
      <Typography variant='subtitle2' gutterBottom align='left'>
        Total amount paid {totalAmountPaid} {asset} in {payments?.length} transaction(s)
      </Typography>
      <Typography variant='subtitle2' gutterBottom align='left'>
        Transaction Details:
      </Typography>
      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Amount Paid</TableCell>
              <TableCell>Payment Asset</TableCell>
              <TableCell>Transaction Network</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <Row key={payment.id} row={payment} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BasicTable;
