import React from "react";

import { Route, Routes } from "react-router-dom";
import Invoice from "./pages/invoice/Invoice";
import NotFound from "./pages/notFound/NotFound";

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/health' element={""} />
        <Route path='/invoice/:id' element={<Invoice />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<External />} />
      </Routes>
    </div>
  );
}

function External() {
  window.location.href = "https://bitcashier.io";
  return null;
}

export default App;
