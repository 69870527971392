import axios from "axios";

export const getInvoiceDetails = (invoiceId) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/public/invoices/${invoiceId}`);
};

export const initializeInvoice = (invoiceId, selectedAsset, selectedMethod) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/public/invoices/${invoiceId}/initialize`, {
    asset: selectedAsset,
    transactionNetwork: selectedMethod,
  });
};
