import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import invoiceReducer from "../pages/invoice/invoiceStore";
import selectedPaymentAssetReducer from "../pages/invoice/SelectedPaymentAssetStore";

export const store = configureStore({
  reducer: {
    invoice: invoiceReducer,
    selectedPaymentAsset: selectedPaymentAssetReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
