import React from "react";
import "./NotFound.scss";

function NotFound() {
  return (
    <div className='error-container'>
      <p className='text'>The following invoice has not been found</p>
    </div>
  );
}

export default NotFound;
